
import { isLivePayEnabled } from "@/helper/LivePayHelper";
import { Options, Prop, Vue } from "vue-property-decorator";
import { namespace } from "s-vuex-class";

const LivePayMandateModule = namespace("live-pay-mandate");

@Options({})
export default class BasicKontoAufladen extends Vue {
  public name = "BasicKontoAufladen";

  @Prop({ default: () => [] })
  public studentEducations!: Array<any>;

  @LivePayMandateModule.Getter("getDataItem")
  public isLivePayMandateSetUp: any;

  protected get isLoadAccountEnabled(): boolean {
    return this.studentEducations.some((studentEducation: any) => isLivePayEnabled(studentEducation)) && this.isLivePayMandateSetUp;
  }

  public onClickAccountCard(): void {
    this.$emit("click");
  }
}


import { Options, Vue } from "vue-property-decorator";

@Options({
  components: {},
  emits: ["close", "mobile-signal"],
})
export default class GocardlessModal extends Vue {
  public name = "GocardlessModal";
}


import { Options, Prop, Vue } from "vue-property-decorator";
import { useIonRouter } from "@ionic/vue";

@Options({
  components: {},
})
export default class Education extends Vue {
  private ionRouter = useIonRouter();

  public onClickEducationCard(): void {
    this.ionRouter.navigate("/education", "forward", "push");
  }

  @Prop({ default: () => false })
  public disabled!: boolean;

  @Prop({ default: () => false })
  public basicView!: boolean;
}


import { computed, defineComponent, PropType, ref } from "vue";
import { IBasicNamedDto } from "@/interfaces/IBasicNamedDto";
import { ITheoryQuestion } from "@/interfaces/IExpiredTheoryQuestion";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Validation from "@/components/Validation.vue";

export default defineComponent({
  name: "CloseToExpireTheoryQuestion",
  components: { Validation },
  props: {
    newVersion: {
      type: Object as PropType<IBasicNamedDto>,
      default: () => [],
    },
    actualVersion: {
      type: Object as PropType<ITheoryQuestion>,
      default: () => [],
    },
    newVersionDisplayValue: {
      type: Object as any,
      default: () => "-",
    },
  },
  emits: ["on-version-change-accepted", "on-version-change-not-accepted"],
  setup(props, { emit }) {
    const actualVersionName = computed<string | null>(() => (props.actualVersion ? props.actualVersion?.name : null));

    const newVersionName = computed<string | null>(() => (props.newVersion ? props.newVersion?.name : null));

    const newVersionDisplayValue = computed<string | null>(() => (props.newVersionDisplayValue ? props.newVersionDisplayValue : "-"));

    const onVersionChangeAccept = () => {
      emit("on-version-change-accepted", props.newVersion ? props.newVersion?.id : null);
    };

    const onVersionChangeNotAccept = () => {
      emit("on-version-change-not-accepted", props.actualVersion ? props.actualVersion?.id : null);
    };

    return {
      onVersionChangeAccept,
      actualVersionName,
      onVersionChangeNotAccept,
      newVersionName,
      newVersionDisplayValue,
    };
  },
});


import { defineComponent } from "vue";
import UserService from "@/services/UserService";

export default defineComponent({
  name: "TrainingContractBlock",
  setup() {
    const drivingSchoolId = UserService.getDrivingSchoolId();
    return {
      drivingSchoolId,
    };
  },
});
